import { appInsights } from '$lib/insights';
import type { HandleClientError } from '@sveltejs/kit';

export const handleError: HandleClientError = async ({ error, event }) => {
  console.log('handleClientError', error);

  const errorId = crypto.randomUUID();
  if (error instanceof Error) {
    appInsights.trackException({
      exception: error,
      properties: {
        event,
        errorId,
      },
    });
  }

  return {
    message: (error as any).message ?? 'Unknown error',
    errorId,
  };
  // console.log('handleError');
  // console.error(event);
  // appInsights.trackTrace({message: 'Error ocurred', properties: { event }, severityLevel: SeverityLevel.Error });
  // return {
  //   message: 'Uhoh'
  // };
};
